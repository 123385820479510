import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { restoreVersionApi } from '../../../api/pipelineApi';
import { JobVersionData } from '../../../types';
import logger from '../../../utils/logger';

const useRestoreVersion = (
  projectId: string,
  jobId: string,
  jobVersionId: string,
  setShowMenu: (showMenu: boolean) => void
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutateAsync: restoreVersionMutateAsync,
    isPending: restoreVersionIsLoading,
    error: restoreVersionError,
    reset: restoreVersionReset,
  } = useMutation<any, Error, string>({
    mutationFn: (jobVersionId) =>
      restoreVersionApi(projectId, jobId, jobVersionId),
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: ['jobDisplay', projectId, jobId],
      });
    },
  });

  const restoreVersion = async (version: JobVersionData) => {
    if (version.id === parseInt(jobVersionId)) {
      alert("You can't restore the current version");
      return;
    }

    if (version.isActive) {
      alert('This version is already active');
      return;
    }

    const res = await restoreVersionMutateAsync(version.id.toString());
    setShowMenu(false);
    if (res.jobVersion.status === 'Processed') {
      navigate(
        `/projects/${projectId}/dashboard/${jobId}/${res.jobVersion.id}`
      );
    } else if (res.jobVersion.status === 'Analyzed') {
      logger.error("Unexpected job status 'Analyzed' in restoreVersion");
      navigate(
        `/projects/${projectId}/dashboard/${jobId}/${res.jobVersion.id}`
      );
    } else if (res.jobVersion.status === 'Codebook') {
      navigate(
        `/projects/${projectId}/${jobId}/${res.jobVersion.id}/split-view-codebook-editor`
      );
    } else if (res.jobVersion.status === 'Failed') {
      navigate(`/projects/${projectId}`);
    } else {
      logger.error(
        `Unexcpected restored version status: ${res.jobVersion.status}`
      );
      navigate(
        `/projects/${projectId}/dashboard/${jobId}/${res.jobVersion.id}`
      );
    }
  };

  return {
    restoreVersion,
    restoreVersionIsLoading,
    restoreVersionError,
    restoreVersionReset,
  };
};

export default useRestoreVersion;

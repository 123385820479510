import React from 'react';

interface XIconProps {
  className?: string;
  color?: string;
}

const XIcon: React.FC<XIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.89016 3.64017C9.03661 3.49372 9.03661 3.25628 8.89016 3.10983C8.74372 2.96339 8.50628 2.96339 8.35984 3.10983L6 5.46967L3.64017 3.10983C3.49372 2.96339 3.25628 2.96339 3.10983 3.10983C2.96339 3.25628 2.96339 3.49372 3.10983 3.64017L5.46967 6L3.10983 8.35984C2.96339 8.50628 2.96339 8.74372 3.10983 8.89016C3.25628 9.03661 3.49372 9.03661 3.64017 8.89016L6 6.53033L8.35984 8.89016C8.50628 9.03661 8.74372 9.03661 8.89016 8.89016C9.03661 8.74372 9.03661 8.50628 8.89016 8.35984L6.53033 6L8.89016 3.64017Z'
          fill='black'
        />
      </g>
    </svg>
  );
};

export default XIcon;

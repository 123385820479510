import { useEffect, useRef, useState } from 'react';

interface UsePeriodicCheckOptions {
  idleTime: number; // Time in seconds to consider user idle
  predicate: () => boolean; // Function to evaluate the second condition
  onAction: () => Promise<any>; // Function to call if both conditions are true
}

const usePeriodicCheck = ({
  idleTime = 10,
  predicate,
  onAction,
}: UsePeriodicCheckOptions): void => {
  const lastActivityTime = useRef(Date.now());
  const [isIdle, setIsIdle] = useState(false);
  const throttling = useRef(false);

  useEffect(() => {
    if (isIdle && predicate()) {
      onAction();
      setIsIdle(false);
    }
  }, [isIdle, predicate, onAction]);

  useEffect(() => {
    const handleActivity = () => {
      if (!throttling.current) {
        lastActivityTime.current = Date.now();
        setIsIdle(false);

        // Start throttling
        throttling.current = true;
        setTimeout(() => {
          throttling.current = false; // Reset throttling after time frame
        }, 3000); // Throttle duration in milliseconds
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsIdle(true); // Mark as idle if tab goes to the background
      } else {
        lastActivityTime.current = Date.now(); // Reset activity on focus
        setIsIdle(false);
      }
    };

    // Attach event listeners for user activity and tab visibility
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Periodic check interval
    const interval = setInterval(() => {
      const idleDuration = (Date.now() - lastActivityTime.current) / 1000; // Convert to seconds
      if (idleDuration >= idleTime || document.hidden) {
        setIsIdle(true);
      }
    }, 1000); // Check every second

    // Cleanup
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(interval);
    };
  }, [idleTime, predicate, onAction, throttling]);
};

export default usePeriodicCheck;

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useReducer, useState } from 'react';
import {
  getManualEditsApi,
  syncCodebookAndTagChangesApi,
} from '../api/pipelineApi';
import { ManualEdit, TagChange, Theme } from '../types';
import logger from '../utils/logger';
import { updateTagChangesStatus } from '../utils/tag-changes-utils';
import { isActiveTagChange } from '../utils/theme-utils';

const areManualTagThemeIdsEqual = (
  t1: Pick<TagChange, 'themeId' | 'themeLocalId'>,
  t2: Pick<TagChange, 'themeId' | 'themeLocalId'>
) => {
  if (t1.themeId !== undefined || t2.themeId !== undefined) {
    return t1.themeId === t2.themeId;
  }

  return t1.themeLocalId === t2.themeLocalId;
};

enum ACTIONS {
  ADD_TAG = 'add_tag',
  REMOVE_TAG = 'remove_tag',
  REMOVE_THEME_TAG_CHANGES = 'remove_theme_tag_changes',
  SET_USER_CONTENT_UNDER_EDIT = 'set_user_content_under_edit',
  START_SYNC = 'start_sync',
  SYNC_SUCCESS = 'sync_success',
  SYNC_FAILURE = 'sync_failure',
  RESET_LOADING_AND_ERROR = 'reset_loading_and_error',
}

export interface UserContentUnderEdit {
  userContentId: number;
  targetBoundingRect: Pick<DOMRect, 'top' | 'left'>;
}

interface State {
  syncLoading: boolean;
  syncError: Error | null;
  tagChangesMap: Record<number, TagChange[]>;
  userContentUnderEdit?: UserContentUnderEdit;
}

type AddAction = {
  type: ACTIONS.ADD_TAG;
  payload: AddRemoveTagParams;
};

type RemoveAction = {
  type: ACTIONS.REMOVE_TAG;
  payload: AddRemoveTagParams;
};

type Action =
  | AddAction
  | RemoveAction
  | {
      type: ACTIONS.REMOVE_THEME_TAG_CHANGES;
      payload: RemoveThemeTagChangesParams;
    }
  | {
      type: ACTIONS.SET_USER_CONTENT_UNDER_EDIT;
      payload: UserContentUnderEdit | undefined;
    }
  | {
      type: ACTIONS.START_SYNC;
      payload: { tagChangesMap: Record<number, TagChange[]> };
    }
  | {
      type: ACTIONS.SYNC_SUCCESS;
      payload: {
        updatedUserContentIds: number[];
      };
    }
  | { type: ACTIONS.SYNC_FAILURE }
  | { type: ACTIONS.RESET_LOADING_AND_ERROR };

const initialState: State = {
  syncLoading: false,
  syncError: null,
  tagChangesMap: {},
  userContentUnderEdit: undefined,
};

const replacePendingTagChanges = (
  tagChangesMap: Record<number, TagChange[]>,
  action: AddAction | RemoveAction,
  type: TagChange['action']
) => {
  const tagChangesMapTemp = { ...tagChangesMap };

  const remainingUserContentTagChanges =
    tagChangesMapTemp[action.payload.userContentId]?.filter(
      (change) =>
        !(
          areManualTagThemeIdsEqual(change, action.payload) &&
          isActiveTagChange(change)
        )
    ) ?? [];

  logger.info(
    `replacePendingTagChanges: remainingUserContentTagChanges: ${remainingUserContentTagChanges} for action: ${
      action.type
    } and payload: ${JSON.stringify(action.payload)}`
  );

  const newTagChanges: TagChange[] = [
    {
      id: Date.now(),
      action: type,
      userContentId: action.payload.userContentId,
      themeId: action.payload.themeId,
      themeLocalId: action.payload.themeLocalId,
      status: 'pending',
      lastEdited: Date.now(),
    },
  ];

  tagChangesMapTemp[action.payload.userContentId] = [
    ...remainingUserContentTagChanges,
    ...newTagChanges,
  ];

  return tagChangesMapTemp;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ACTIONS.ADD_TAG: {
      // remove any existing pending tag changes for this userContentId
      logger.info('addTag reducer: payload: ', action.payload);

      const hasSimilarPendingAddChange = state.tagChangesMap[
        action.payload.userContentId
      ]?.some(
        (change) =>
          areManualTagThemeIdsEqual(change, action.payload) &&
          isActiveTagChange(change) &&
          change.action === 'add'
      );
      if (hasSimilarPendingAddChange) {
        logger.info(
          'addTag reducer: existing pending add change found, return existing state'
        );
        return state;
      }

      const tagChangesMapTemp = replacePendingTagChanges(
        state.tagChangesMap,
        action,
        'add'
      );

      return {
        ...state,
        tagChangesMap: { ...tagChangesMapTemp },
      };
    }

    case ACTIONS.REMOVE_TAG: {
      logger.info('removeTag reducer: payload: ', action.payload);

      const hasSimilarPendingRemoveChange = state.tagChangesMap[
        action.payload.userContentId
      ]?.some(
        (change) =>
          areManualTagThemeIdsEqual(change, action.payload) &&
          isActiveTagChange(change) &&
          change.action === 'remove'
      );
      if (hasSimilarPendingRemoveChange) {
        logger.error(
          'removeTag reducer unexpected state: existing pending remove change found, return existing state'
        );
        return state;
      }

      const tagChangesMapTemp = replacePendingTagChanges(
        state.tagChangesMap,
        action,
        'remove'
      );

      return {
        ...state,
        tagChangesMap: { ...tagChangesMapTemp },
      };
    }

    case ACTIONS.REMOVE_THEME_TAG_CHANGES: {
      logger.info('removeThemeTags reducer: payload: ', action.payload);

      const tagChangesMapTemp = Object.fromEntries(
        Object.entries(state.tagChangesMap).map(
          ([userContentId, tagChanges]) => {
            return [
              userContentId,
              tagChanges.filter(
                (tagChange) =>
                  !areManualTagThemeIdsEqual(tagChange, action.payload)
              ),
            ];
          }
        )
      );

      return {
        ...state,
        tagChangesMap: tagChangesMapTemp,
      };
    }

    case ACTIONS.SET_USER_CONTENT_UNDER_EDIT: {
      logger.info('setUserContentUnderEdit reducer: payload: ', action.payload);
      // if payload is undefined, it means we're removing the userContentUnderEdit
      if (!action.payload) {
        return {
          ...state,
          userContentUnderEdit: undefined,
        };
      }
      // extract top and right from the targetBoundingRect
      const { top, left } = action.payload.targetBoundingRect;
      return {
        ...state,
        userContentUnderEdit: {
          userContentId: action.payload.userContentId,
          targetBoundingRect: {
            top,
            left,
          },
        },
      };
    }

    case ACTIONS.START_SYNC: {
      logger.info('startSync reducer: starting sync');

      return {
        ...state,
        syncLoading: true,
        syncError: null,
        tagChangesMap: action.payload.tagChangesMap,
      };
    }

    case ACTIONS.SYNC_SUCCESS: {
      const { updatedUserContentIds } = action.payload;

      logger.info(
        'syncSuccess reducer: updatedUserContentIds: ',
        updatedUserContentIds
      );

      const updatedTagChangesMap = updateTagChangesStatus(
        state.tagChangesMap,
        'syncing',
        'synced'
      );

      return {
        ...state,
        syncLoading: false,
        syncError: null,
        tagChangesMap: updatedTagChangesMap,
      };
    }

    case ACTIONS.SYNC_FAILURE: {
      logger.info(
        'syncFailure reducer, converting all syncing tag changes to pending'
      );

      const updatedTagChangesMap = updateTagChangesStatus(
        state.tagChangesMap,
        'syncing',
        'pending'
      );

      return {
        ...state,
        syncLoading: false,
        // syncError: new Error('Syncing failed'),
        tagChangesMap: updatedTagChangesMap,
      };
    }

    case ACTIONS.RESET_LOADING_AND_ERROR:
      return {
        ...state,
        syncLoading: false,
        syncError: null,
      };

    default:
      return state;
  }
}

export type AddRemoveTagParams = {
  userContentId: number;
  themeId?: number;
  themeLocalId?: number;
};

export type RemoveThemeTagChangesParams = {
  themeId?: number;
  themeLocalId?: number;
};

function useManualTagManager(jobVersionId: string) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const [manualEditsMap, setManualEditsMap] = useState<
    Record<number, ManualEdit[]>
  >({});

  const {
    data: manualEditsData,
    isPending: manualEditsLoading,
    // error: manualEditsError, // TODO: remove this on handle error flow
  } = useQuery<any, Error, ManualEdit[]>({
    queryKey: ['manualEdits', jobVersionId],
    queryFn: () => getManualEditsApi(jobVersionId!),
    retry: 3,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (manualEditsData) {
      const map = manualEditsData.reduce((acc, manualEdit) => {
        if (!acc[manualEdit.userContentId]) {
          acc[manualEdit.userContentId] = [];
        }
        acc[manualEdit.userContentId].push(manualEdit);
        return acc;
      }, {} as Record<number, ManualEdit[]>);

      // Sort manual edits by lastEdited date for each user content
      Object.keys(map).forEach((key: string) => {
        map[key as any].sort(
          (a: ManualEdit, b: ManualEdit) =>
            new Date(a.lastEdited).getTime() - new Date(b.lastEdited).getTime()
        );
      });
      setManualEditsMap(map);

      dispatch({
        type: ACTIONS.SYNC_SUCCESS,
        payload: { updatedUserContentIds: [] },
      });
    }
  }, [manualEditsData]);

  // Function to reset the error state
  const resetManualEdits = () => {
    // this causes the codebook to be not 'dirty' hence disabling the refresh current page button
    // queryClient.invalidateQueries({ queryKey: ['themes', jobVersionId] });
    queryClient.invalidateQueries({ queryKey: ['manualEdits', jobVersionId] });
  };

  const addTag = ({
    userContentId,
    themeId,
    themeLocalId,
  }: AddRemoveTagParams) => {
    dispatch({
      type: ACTIONS.ADD_TAG,
      payload: { userContentId, themeId, themeLocalId },
    });
  };

  const removeTag = ({
    userContentId,
    themeId,
    themeLocalId,
  }: AddRemoveTagParams) => {
    dispatch({
      type: ACTIONS.REMOVE_TAG,
      payload: { userContentId, themeId, themeLocalId },
    });
  };

  const removeThemeTagChanges = ({
    themeId,
    themeLocalId,
  }: RemoveThemeTagChangesParams) => {
    dispatch({
      type: ACTIONS.REMOVE_THEME_TAG_CHANGES,
      payload: { themeId, themeLocalId },
    });
  };

  const setUserContentUnderEdit = (
    userContentUnderEdit: UserContentUnderEdit | undefined
  ) => {
    dispatch({
      type: ACTIONS.SET_USER_CONTENT_UNDER_EDIT,
      payload: userContentUnderEdit,
    });
  };

  async function syncCodebookAndTagChanges(
    jobVersionId: string,
    themes: Theme[]
  ) {
    const updatedTagChangesMap = updateTagChangesStatus(
      state.tagChangesMap,
      'pending',
      'syncing'
    );

    // Mark all pending changes as syncing
    dispatch({
      type: ACTIONS.START_SYNC,
      payload: { tagChangesMap: updatedTagChangesMap },
    });

    try {
      // Flatten tagChangesMap into an array of syncing changes
      const syncingTagChanges = Object.entries(updatedTagChangesMap).flatMap(
        ([, tagChanges]) =>
          tagChanges.filter((tagChange) => tagChange.status === 'syncing')
      );

      // Log the syncing changes for debugging
      logger.info('syncTagChanges: syncingTagChanges: ', syncingTagChanges);

      // Send syncing changes to the server
      const { updatedUserContentIds, localToServerIdMap } =
        await syncCodebookAndTagChangesApi(
          jobVersionId,
          themes,
          syncingTagChanges
        );

      logger.info(
        'syncCodebookAndTagChanges: updatedUserContentIds: ',
        updatedUserContentIds
      );

      resetManualEdits();
      // Dispatch success action with updated manual edits and user content IDs
      // dispatch({
      //   type: ACTIONS.SYNC_SUCCESS,
      //   payload: { updatedUserContentIds },
      // });

      return localToServerIdMap;
    } catch (error) {
      logger.error('syncCodebookAndTagChanges: Sync failed: ', error);

      // Dispatch failure action
      dispatch({ type: ACTIONS.SYNC_FAILURE });
    }
  }

  const resetLoadingAndError = () => {
    dispatch({ type: ACTIONS.RESET_LOADING_AND_ERROR });
  };

  return {
    ...state,
    addTag,
    removeTag,
    removeThemeTagChanges,
    setUserContentUnderEdit,
    syncCodebookAndTagChanges,
    resetLoadingAndError,
    manualEditsMap,
    manualEditsLoading,
  };
}

export default useManualTagManager;

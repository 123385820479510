import React, { PropsWithChildren, createContext, useState } from 'react';
import useUpdateClientVersion from '../hooks/useUpdateClientVersion';

interface FormContextType {
  isDemo: boolean;
  setIsDemo: React.Dispatch<React.SetStateAction<boolean>>;
  showUpdateAppVersionDialog: boolean;
  refreshAppVersion: () => void;
  dismissUpdateAppVersionDialog: () => void;
  includeAggregateProjectIds: number[];
  setIncludeAggregateProjectIds: React.Dispatch<React.SetStateAction<number[]>>;
}

export const FormContext = createContext<FormContextType>({
  isDemo: false,
  setIsDemo: () => {},
  showUpdateAppVersionDialog: false,
  refreshAppVersion: () => {},
  dismissUpdateAppVersionDialog: () => {},
  includeAggregateProjectIds: [],
  setIncludeAggregateProjectIds: () => {},
});

export const FormProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isDemo, setIsDemo] = useState(false);
  const [includeAggregateProjectIds, setIncludeAggregateProjectIds] = useState<
    number[]
  >([]);

  const { showDialog, handleRefresh, handleDismiss } = useUpdateClientVersion();

  return (
    <FormContext.Provider
      value={{
        isDemo,
        setIsDemo,
        showUpdateAppVersionDialog: showDialog,
        dismissUpdateAppVersionDialog: handleDismiss,
        refreshAppVersion: handleRefresh,
        includeAggregateProjectIds,
        setIncludeAggregateProjectIds,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

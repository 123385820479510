import { TagChange } from '../types';

export const updateTagChangesStatus = (
  tagChangesMap: Record<number, TagChange[]>,
  fromStatus: 'pending' | 'syncing',
  toStatus: 'pending' | 'syncing' | 'synced'
) => {
  const updatedTagChangesMap: Record<number, TagChange[]> = Object.fromEntries(
    Object.entries(tagChangesMap).map(([userContentId, tagChanges]) => [
      userContentId,
      tagChanges.map((tagChange) =>
        tagChange.status === fromStatus
          ? { ...tagChange, status: toStatus }
          : tagChange
      ),
    ])
  );

  return updatedTagChangesMap;
};

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { redirectToAuth } from 'supertokens-auth-react';
import Passwordless from 'supertokens-auth-react/recipe/passwordless';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui';
import Session, {
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';
import {
  AuthPage,
  AuthPageTheme,
  AuthPageThemeProps,
} from 'supertokens-auth-react/ui';
import logger from '../utils/logger';
import { removeUser } from '../utils/userLocalStorage';
import SecondFactorClaim from './SecondFactorClaim';

const CustomAuthPageTheme: React.FC<AuthPageThemeProps> = (props) => {
  const [showDefaultUI, setShowDefaultUI] = useState(false);
  const session = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    let aborting = false;
    async function effect() {
      if (session.loading === true) {
        return;
      }

      if (!session.invalidClaims.some((e) => e.id === SecondFactorClaim.id)) {
        navigate('/');
        return;
      }

      const email = session.accessTokenPayload.email;
      logger.info('SecondFactor using email: ', email);

      // If don't have an email we show the default UI (which should be the email form)
      if (!email) {
        logger.error('CustomAuthPageTheme - no email, removing user');
        removeUser();
        await Session.signOut();
        // setShowDefaultUI(true);
      } else {
        const attemptInfo = await Passwordless.getLoginAttemptInfo();

        if (aborting) {
          return;
        }
        if (attemptInfo === undefined) {
          const additionalAttemptInfo = {
            lastResend: Date.now(),
            contactMethod: 'EMAIL',
            contactInfo: email,
            redirectToPath: '/',
          };

          const res = await Passwordless.createCode({
            email: email,
            userContext: { additionalAttemptInfo },
          });

          if (res.status === 'OK') {
            props.rebuildAuthPage();
          } else {
            props.onError(res.reason ?? res.status);
          }
        }
        // if we have an OTP flow (or we just tried to start one) we show the default UI which should be the OTP input
        setShowDefaultUI(true);
      }
    }
    effect();
    return () => {
      aborting = true;
    };
  }, [session.loading]);

  if (showDefaultUI) {
    return (
      <>
        <AuthPageTheme {...props} />

        <div
          onClick={async () => {
            await Passwordless.clearLoginAttemptInfo();
            await Session.signOut();
            redirectToAuth({ redirectBack: false });
          }}
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            margin: 'auto',
            width: 'fit-content',
          }}
        >
          Login with another account
        </div>
      </>
    );
  }
  return <></>;
};

export default function SecondFactor() {
  return (
    <AuthPage
      preBuiltUIList={[PasswordlessPreBuiltUI]}
      factors={['otp-email']}
      redirectOnSessionExists={false}
    >
      {
        // @ts-ignore We ignore the error about missing props, since they'll be set by the feature component
        <CustomAuthPageTheme />
      }
    </AuthPage>
  );
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddRemoveTagParams } from '../../hooks/useManualTagManager';
import { Colors } from '../../theme/Colors';
import { ManualEdit, TagChange, TaggedUserContent, Theme } from '../../types';
import {
  getActiveTagChangesAddedThemes,
  getFilteredManuallyAddedThemes,
  getSystemTagsNotHandledManually,
} from '../../utils/theme-utils';
import ButtonWithIcon from '../buttons/ButtonWithIcon';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import CircledPlusIconWithActive from '../icons/CircledPlusIconWithActive';
import P1 from '../newTextComponents/P1';
import P3 from '../newTextComponents/P3';
import Tag from './Tag';

interface TaggedContentItemProps {
  taggedUserContent: TaggedUserContent;
  themes: Theme[];
  shouldAlignRight?: boolean;
  codebookLanguage: 'Hebrew' | 'English';
  translationEnabled?: boolean;
  onAddTag: (userContentId: number, boundingRect: DOMRect) => void;
  onRemoveTag: ({
    userContentId,
    themeId,
    themeLocalId,
  }: AddRemoveTagParams) => void;
  tagChanges: TagChange[];
  manualEdits: ManualEdit[];
}

const TaggedContentItem: React.FC<TaggedContentItemProps> = ({
  taggedUserContent,
  themes,
  shouldAlignRight,
  codebookLanguage,
  translationEnabled,
  onAddTag,
  onRemoveTag,
  tagChanges = [],
  manualEdits = [],
}) => {
  const [showTranslation, setShowTranslation] = useState(!!translationEnabled);
  const hasTranslation = !!taggedUserContent.translatedText;

  const persistedThemeIds = themes
    .filter((t) => t.id !== undefined)
    .map((theme) => theme.id) as number[];

  useEffect(() => {
    setShowTranslation(!!translationEnabled);
  }, [translationEnabled]);

  const toggleTranslation = () => {
    setShowTranslation(!showTranslation);
  };

  const displayText =
    showTranslation && taggedUserContent.translatedText
      ? taggedUserContent.translatedText
      : taggedUserContent.text;

  const sourceLanguage = taggedUserContent.sourceLanguageISOCode;

  const systemTagsNotHandledManually = getSystemTagsNotHandledManually(
    taggedUserContent,
    manualEdits,
    tagChanges,
    persistedThemeIds
  );

  const filteredManuallyAddedThemes = getFilteredManuallyAddedThemes(
    themes,
    manualEdits,
    tagChanges
  );

  const activeTagChangesAddedThemes = getActiveTagChangesAddedThemes(
    themes,
    tagChanges
  );

  const hasPersistedOther =
    taggedUserContent.tags.some((tag) => tag.themeName === 'Other') &&
    activeTagChangesAddedThemes.length === 0 &&
    filteredManuallyAddedThemes.length === 0;

  const shouldDisplayManualOther =
    !hasPersistedOther &&
    activeTagChangesAddedThemes.length === 0 &&
    filteredManuallyAddedThemes.length === 0 &&
    systemTagsNotHandledManually.length === 0;

  const isTagsContainerRightAligned = codebookLanguage === 'Hebrew';
  return (
    <ItemContainer>
      <ContentContainer $shouldAlignRight={shouldAlignRight}>
        <FeedbackText id={`${taggedUserContent.id}`}>
          {displayText}
        </FeedbackText>
        {translationEnabled && hasTranslation && sourceLanguage && (
          <LanguageISOCodeButton
            onClick={toggleTranslation}
            // label={sourceLanguage || ''}
            // style={{ padding: '4px 16px' }}
            $showTranslation={showTranslation}
          >
            <SourceLanguageText $showTranslation={showTranslation}>
              {sourceLanguage}
            </SourceLanguageText>
          </LanguageISOCodeButton>
        )}
      </ContentContainer>
      <TagsContainer $shouldAlignRight={isTagsContainerRightAligned}>
        {hasPersistedOther && <Tag type='tag' text={'Other'} />}
        {shouldDisplayManualOther && <Tag type='manualTag' text={'Other'} />}
        {systemTagsNotHandledManually.map((tag, index) => (
          <Tag
            key={index}
            type='tag'
            text={tag.themeName}
            onRemove={() => {
              onRemoveTag({
                userContentId: taggedUserContent.id,
                themeId: tag.themeId,
              });
            }}
            isRTL={isTagsContainerRightAligned}
          />
        ))}
        {filteredManuallyAddedThemes.map((theme, index) => (
          <Tag
            key={index}
            type='manualTag'
            text={theme?.name || ''}
            onRemove={() => {
              onRemoveTag({
                userContentId: taggedUserContent.id,
                themeId: theme?.id,
              });
            }}
            isRTL={isTagsContainerRightAligned}
          />
        ))}
        {activeTagChangesAddedThemes.map((theme, index) => (
          <Tag
            key={index}
            type='manualTag'
            text={theme?.name || ''}
            onRemove={() => {
              onRemoveTag({
                userContentId: taggedUserContent.id,
                themeId: theme?.id,
                themeLocalId: theme?.localId,
              });
            }}
            isRTL={isTagsContainerRightAligned}
          />
        ))}
        <AddTagButton
          icon={<CircledPlusIconWithActive />}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            const buttonRect = e.currentTarget.getBoundingClientRect();
            onAddTag(taggedUserContent.id, buttonRect);
          }}
        />
      </TagsContainer>
    </ItemContainer>
  );
};

export default TaggedContentItem;

const ItemContainer = styled(VerticalFlex)`
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  gap: 12px;
  position: relative;
`;

const AddTagButton = styled(ButtonWithIcon)`
  /* display: none; */
  padding: 0;
`;

const ContentContainer = styled(HorizontalFlex)<{
  $shouldAlignRight?: boolean;
}>`
  justify-content: space-between;
  ${({ $shouldAlignRight }) =>
    $shouldAlignRight ? 'text-align: right; direction: rtl;' : ''}
`;

const SourceLanguageText = styled(P3)<{
  $showTranslation: boolean;
}>`
  font-size: 11px;
  line-height: 12px;
  ${({ $showTranslation }) =>
    $showTranslation ? `color: ${Colors.B40};` : `color: ${Colors.P70};`}
`;

const LanguageISOCodeButton = styled.button<{
  $showTranslation: boolean;
}>`
  background-color: transparent;
  outline: none;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
    border-color: ${Colors.P40};
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px ${Colors.B20} solid;
  border-radius: 10px;
  padding: 0;
  width: 21px;
  min-width: 21px;
  height: 21px;
  ${({ $showTranslation }) =>
    $showTranslation
      ? `background-color: ${Colors.WHITE};`
      : `background-color: ${Colors.P5}; border: 1px ${Colors.P40} solid;`}
`;

const FeedbackText = styled(P1)``;

const TagsContainer = styled(CenteredHorizontalFlex)<{
  $shouldAlignRight: boolean;
}>`
  flex-wrap: wrap;
  gap: 8px;
  direction: ${({ $shouldAlignRight }) => ($shouldAlignRight ? 'rtl' : 'ltr')};
`;

import React from 'react';
import styled from 'styled-components';
import Label from '../../Label';

interface EmptyResultsProps {}

const EmptyResults: React.FC<EmptyResultsProps> = ({}) => {
  return (
    <Container>
      <Text>
        No existing theme found. Press 'Return' to create a new theme with this
        name.
      </Text>
    </Container>
  );
};

export default EmptyResults;

const Container = styled.div`
  margin-top: 7px;
  box-shadow: 0px 2px 12px rgba(45, 45, 45, 0.1);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 272px;
`;

const Text = styled(Label)`
  width: 275px;
`;

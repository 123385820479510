import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import { ProjectData } from '../../types';
import { formatDateString } from '../../utils/utils';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import MessageModal from '../modal/MessageModal';
import P1 from '../newTextComponents/P1';
import P2 from '../newTextComponents/P2';
import ProjectItemMenu from './ProjectItemMenu';
import ProjectRenameInputModal from './ProjectRenameInputModal';

interface ListProjectItemProps {
  project: ProjectData;
  onClick: VoidFunction;
  disabled: boolean;
  renameProject: (newName: string) => void;
  removeProject: () => void;
  isDemo?: boolean;
  showAggregate?: boolean;
  includeAggregateCheckbox?: boolean;
  onSelectProjectAggregate?: () => void;
}

const ListProjectItem: React.FC<ListProjectItemProps> = ({
  project,
  onClick,
  disabled,
  renameProject,
  removeProject,
  isDemo,
  showAggregate,
  includeAggregateCheckbox,
  onSelectProjectAggregate,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showRemoveProjectWarningModal, setShowRemoveProjectWarningModal] =
    useState(false);

  const projectName =
    project.old_status === 'Processing'
      ? project.name + ' (Tagging...)'
      : isDemo
      ? project.name +
        (project.old_status?.[0]
          ? ` (${project.old_status?.[0]})`
          : project.status
          ? ` [${project.status}]`
          : '')
      : project.name;

  const [projectNameLocalState, setProjectNameLocalState] =
    useState(projectName);

  const onRenameProjectClick = () => {
    renameProject(projectNameLocalState);
    setShowRenameModal(false);
  };

  const onRemoveProjectClick = () => {
    removeProject();
    setShowRemoveProjectWarningModal(false);
  };

  const onClickListProjectItem = () => {
    if (showMenu) return;

    onClick();
  };

  return (
    <Container
      onClick={onClickListProjectItem}
      $disabled={disabled || showMenu}
    >
      <ProjectItemMenu
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        onRenameProject={() => setShowRenameModal(true)}
        onRemoveProject={() => setShowRemoveProjectWarningModal(true)}
      />
      <ImageContainer>
        <Image src={project.image} />
        <ProjectName>{projectName}</ProjectName>
        {showAggregate ? (
          <div
            style={{ padding: 16 }}
            onClick={(e) => {
              e.stopPropagation();
              onSelectProjectAggregate?.();
            }}
          >
            <ToggleInput
              type='checkbox'
              checked={includeAggregateCheckbox}
              onChange={() => {
                // onSelectProjectAggregate?.();
              }}
            />
          </div>
        ) : null}
      </ImageContainer>
      <QuestionsCount>{project.questions_counter}</QuestionsCount>
      <CreatedAt>{formatDateString(project.created_at)}</CreatedAt>
      {showRenameModal ? (
        <ProjectRenameInputModal
          show={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          value={projectNameLocalState}
          onChange={setProjectNameLocalState}
          onRenameProjectClick={onRenameProjectClick}
        />
      ) : null}
      <MessageModal
        show={showRemoveProjectWarningModal}
        onClose={() => setShowRemoveProjectWarningModal(false)}
        cancelButtonText='Cancel'
        approveButtonText='OK'
        onApprove={onRemoveProjectClick}
        title='Remove Project'
        subTitle='Are you sure you want to remove this project?'
      />
    </Container>
  );
};

export default ListProjectItem;

const Container = styled(CenteredHorizontalFlex)<{ $disabled: boolean }>`
  height: 74px;
  justify-content: space-between;
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  position: relative;
  &:hover {
    background-color: ${Colors.P10};
  }

  &:hover > .menu-icon-visible {
    // Targeting the child component on hover
    opacity: 1;
    visibility: visible;
  }
`;

const ToggleInput = styled.input`
  &:checked + span {
    background-color: ${Colors.P100};
  }

  &:focus + span {
    box-shadow: 0 0 1px ${Colors.P100};
  }

  &:checked + span:before {
    transform: translateX(16px);
  }
`;

const ProjectName = styled(P1)`
  text-align: center;
  font-weight: 500;
`;

const ImageContainer = styled(CenteredHorizontalFlex)`
  gap: 16px;
  flex: 2;
`;

const TableCell = styled(P2)`
  color: ${Colors.B80};
`;

const QuestionsCount = styled(TableCell)`
  flex: 1;
`;
const CreatedAt = styled(TableCell)`
  flex: 1;
`;

const Image = styled.img`
  border-radius: 10px;
  width: 42px;
  height: 42px;
  object-fit: cover;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);

  :hover {
    transform: scale(1.03);
    transition: all 0.2s ease-out;
  }
`;

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {
  AddRemoveTagParams,
  UserContentUnderEdit,
} from '../../hooks/useManualTagManager';
import { Colors } from '../../theme/Colors';
import { ManualEdit, TagChange, TaggedUserContent, Theme } from '../../types';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import logger from '../../utils/logger';
import { areThemeIdsEqual } from '../../utils/theme-utils';
import { checkHebrewPercentage } from '../../utils/utils';
import FilterButtonWithActive from '../buttons/FilterButtonWithActive';
import ReloadButtonWithActive from '../buttons/ReloadButtonWithActive';
import Tooltip from '../buttons/Tooltip';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import HorizontalFlex from '../containers/HorizontalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import AutoCompleteSelectFilter from '../form/select/AutoCompleteSelectFilter';
import AutoCompleteSelectTag from '../form/select/AutoCompleteSelectTag';
import FilterIcon from '../icons/FilterIcon';
import ReloadIcon from '../icons/ReloadIcon';
import ErrorModal from '../modal/ErrorModal';
import H3 from '../newTextComponents/H3';
import P2 from '../newTextComponents/P2';
import LoadingModal from './LoadingModal';
import TaggedContentItem from './TaggedContentItem';
import TagResultsLoader from './TagResultsLoader';

interface TagResultsWithScrollToEndProps {
  results?: TaggedUserContent[];
  themesDraft: Theme[];
  nonEmptyUserContentCount?: number;
  onScrollToEnd: VoidFunction;
  isFetchingNextPage?: boolean;
  pageIsLoading?: boolean;
  codebookIsDirty: boolean;
  createTheme: (themeName: string, instructions?: string) => number;
  addSelectedTextToTheme: (selectedText: string, themeToTagBy: Theme) => void;
  codebookLanguage: 'Hebrew' | 'English';
  isCodebookInFirstVersion: boolean;
  filters: Theme[];
  setFilters: React.Dispatch<React.SetStateAction<Theme[]>>;
  translationEnabled?: boolean;
  tagChangesMap: Record<number, TagChange[]>;
  userContentUnderEdit?: {
    userContentId: number;
    targetBoundingRect: Pick<DOMRect, 'top' | 'left'>;
  };
  syncLoading: boolean;
  syncError: Error | null;
  resetLoadingAndError: () => void;
  addTag: (params: AddRemoveTagParams) => void;
  removeTag: (params: AddRemoveTagParams) => void;
  setUserContentUnderEdit: (
    userContentUnderEdit?: UserContentUnderEdit
  ) => void;
  manualEditsMap: Record<number, ManualEdit[]>;
  manualEditsLoading: boolean;
  refreshCurrentPage: () => Promise<void>;
}

const TagResultsWithScrollToEnd: React.FC<
  PropsWithChildren<TagResultsWithScrollToEndProps>
> = ({
  results,
  themesDraft,
  nonEmptyUserContentCount,
  onScrollToEnd,
  isFetchingNextPage,
  pageIsLoading,
  codebookIsDirty,
  createTheme,
  addSelectedTextToTheme,
  codebookLanguage = 'English',
  isCodebookInFirstVersion,
  filters,
  setFilters,
  translationEnabled,
  tagChangesMap,
  userContentUnderEdit,
  syncLoading,
  syncError,
  resetLoadingAndError,
  addTag,
  removeTag,
  setUserContentUnderEdit,
  manualEditsMap,
  manualEditsLoading,
  refreshCurrentPage,
}) => {
  // Other id is used for comparison
  const filtersWithOther = [{ name: 'Other', id: 9991 }, ...themesDraft];
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filterIconBoundingRect, setFilterIconBoundingRect] = useState<
    DOMRect | undefined
  >();

  const filterIconRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (filterIconRef?.current) {
      setFilterIconBoundingRect(filterIconRef.current.getBoundingClientRect());
    }
  }, [filterIconRef]);

  const [selectedText, setSelectedText] = useState<string | undefined>();
  const [textHighlightUserContentId, setTextHighlightUserContentId] = useState<
    number | undefined
  >();

  const [selectedTextBoundingRect, setSelectedTextBoundingRect] = useState<
    Pick<DOMRect, 'top' | 'left'> | undefined
  >();

  const resetAddTagWidget = () => {
    logger.info('resetAddTagWidget');
    setUserContentUnderEdit(undefined);
  };

  const resetTextHighlight = () => {
    logger.info('resetTextHighlight');
    setSelectedTextBoundingRect(undefined);
    setSelectedText(undefined);
    setTextHighlightUserContentId(undefined);
    resetAddTagWidget();
  };

  const handleScroll = useCallback(() => {
    const listContainer = scrollContainerRef.current;
    if (listContainer) {
      const isAtBottom =
        listContainer.scrollTop + listContainer.clientHeight >
        listContainer.scrollHeight - 4500;
      // listContainer.scrollHeight - 300;
      if (isAtBottom) {
        // Call your callback function here
        if (!isFetchingNextPage) {
          onScrollToEnd();
        }
      }
    }
  }, [isFetchingNextPage, onScrollToEnd]);

  useEffect(() => {
    handleScroll();
  }, [filters, handleScroll]);

  useEffect(() => {
    const listContainer = scrollContainerRef.current;
    if (listContainer) {
      listContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listContainer) {
        listContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef.current, handleScroll]);

  const calculateAddThemesModalPosition = (clickedElementPosition: DOMRect) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Assuming the component width is 400px and height is 300px
    // You might want to measure this dynamically for more accuracy
    const componentWidth = 440;
    const componentHeight = 370;

    let top = clickedElementPosition.y + 60;
    let left = clickedElementPosition.x + 100;

    // Check right overflow
    if (left + componentWidth > viewportWidth) {
      left = clickedElementPosition.x - componentWidth;
    }

    // Check bottom overflow
    if (top + componentHeight > viewportHeight) {
      top = clickedElementPosition.y - componentHeight - 40; // 10px offset
    }

    return { top, left };
  };

  const deferredHandleTextSelect = () => {
    logger.info('deferredHandleTextSelect');
    const selectionElement = window.getSelection();

    if (!selectionElement) {
      return;
    }

    const spanElement = selectionElement.focusNode?.parentElement;
    if (!spanElement || !spanElement.id) {
      logger.error('No span element or id');
      return;
    }
    const selectionBoundingRect = spanElement.getBoundingClientRect();
    setTextHighlightUserContentId(Number(spanElement.id));

    const selectedText = selectionElement.toString();
    if (!selectionBoundingRect || !selectedText || selectedText.length < 2) {
      return;
    }
    setSelectedText(selectedText);
    const { top, left } = calculateAddThemesModalPosition(
      selectionBoundingRect
    );
    setSelectedTextBoundingRect({ top, left });
  };

  const handleTextSelect = () => {
    logger.info('handleTextSelect');
    setTimeout(deferredHandleTextSelect, 0);
  };

  const textSelectionStarted = () => {
    logger.info('textSelectionStarted ');
    resetTextHighlight();
    document.addEventListener('mouseup', handleTextSelect, { once: true });
  };

  const displayAddTagWidget = (
    userContentId: number,
    boundingRect: DOMRect
  ) => {
    const { top, left } = calculateAddThemesModalPosition(boundingRect);
    setUserContentUnderEdit({
      userContentId,
      targetBoundingRect: { top, left },
    });
  };

  const createThemeAndTagContent = (
    themeName: string,
    userContentId: number,
    instructions?: string
  ) => {
    const themeLocalId = createTheme(themeName, instructions);
    addTag({ themeLocalId, userContentId });
  };

  const [isHebrew, setIsHebrew] = useState(false);

  useEffect(() => {
    if (!results) return;

    setIsHebrew(checkHebrewPercentage(results.map((f) => f.text)));
  }, [results]);

  const updateTagsButtonDisabled = pageIsLoading || !codebookIsDirty;
  const filterTagsButtonDisabled = pageIsLoading;
  const tagSampleCounter =
    nonEmptyUserContentCount && results
      ? `( ${results.length} / ${nonEmptyUserContentCount} )`
      : '';

  return (
    <Container>
      <Content>
        <TitleContainer>
          <H3>Tagged Survey Responses</H3>
          <P2>{tagSampleCounter}</P2>
        </TitleContainer>
        <Buttons>
          <Tooltip toolTipText='Filter by theme' disabled={true} shiftLeft>
            <div ref={filterIconRef} />
            <FilterButtonWithActive
              icon={<StyledFilterIcon />}
              onClick={() => setShowFilterMenu((a) => !a)}
              disabled={filterTagsButtonDisabled}
            />
          </Tooltip>
          <Tooltip
            toolTipText='Refresh to apply latest changes'
            disabled={updateTagsButtonDisabled}
            shiftLeft
          >
            <ReloadButtonWithActive
              icon={<StyledReloadIcon />}
              onClick={refreshCurrentPage}
              disabled={updateTagsButtonDisabled}
            />
          </Tooltip>
        </Buttons>
      </Content>
      {pageIsLoading ? (
        <TagResultsLoader isFirstLoad={isCodebookInFirstVersion} />
      ) : (
        <ContentsContainer
          ref={scrollContainerRef}
          onMouseDown={textSelectionStarted}
        >
          {results && (
            <ListContainer>
              {results.map((feedback, index) => (
                <TaggedContentItem
                  shouldAlignRight={isHebrew}
                  codebookLanguage={codebookLanguage}
                  key={feedback.id || index}
                  taggedUserContent={feedback}
                  tagChanges={tagChangesMap[feedback.id]}
                  manualEdits={manualEditsMap[feedback.id]}
                  themes={themesDraft}
                  translationEnabled={translationEnabled}
                  onAddTag={displayAddTagWidget}
                  onRemoveTag={removeTag}
                />
              ))}
            </ListContainer>
          )}
          {isFetchingNextPage ? <TagResultsLoader isFetchingNextPage /> : null}
        </ContentsContainer>
      )}
      {selectedTextBoundingRect &&
        selectedText &&
        textHighlightUserContentId && (
          <AutoCompleteSelectTag
            themes={themesDraft}
            placeholder='Enter tag name...'
            label={`tag - ${selectedText}`}
            disabled={false}
            position={selectedTextBoundingRect}
            onSelectTheme={(theme) => {
              logger.info('onSelectTheme: ', theme);
              addSelectedTextToTheme(selectedText, theme);
              addTag({
                themeId: theme.id,
                themeLocalId: theme.localId,
                userContentId: textHighlightUserContentId,
              });
              resetTextHighlight();
            }}
            onInputKeyDown={(inputValue) => {
              logger.info('onInputKeyDown: ', inputValue);
              createThemeAndTagContent(
                inputValue,
                textHighlightUserContentId,
                selectedText
              );
              resetTextHighlight();
            }}
            close={() => {
              logger.info('close AutoCompleteSelect ');
              resetTextHighlight();
            }}
          />
        )}
      {userContentUnderEdit && (
        <AutoCompleteSelectTag
          themes={themesDraft}
          placeholder='Enter tag name...'
          label={`Tag Response`}
          disabled={false}
          position={userContentUnderEdit.targetBoundingRect}
          onSelectTheme={(theme: Theme) => {
            logger.info('Manual Add Tag - onSelectTheme: ', theme);
            addTag({
              themeId: theme.id,
              themeLocalId: theme.localId,
              userContentId: userContentUnderEdit.userContentId,
            });
            resetAddTagWidget();
          }}
          onInputKeyDown={(inputValue) => {
            logger.info('Manual Add Tag - onInputKeyDown: ', inputValue);
            createThemeAndTagContent(
              inputValue,
              userContentUnderEdit.userContentId
            );
            resetAddTagWidget();
          }}
          // onCreateNewTheme={(inputValue) => {
          //   logger.info('Manual Add Tag - onCreateNewTheme: ', inputValue);
          //   handleCreateNewTheme(
          //     inputValue,
          //     userContentUnderEdit.userContentId
          //   );
          //   resetAddTagWidget()
          // }}
          close={() => {
            logger.info('Manual Add Tag - close');
            resetAddTagWidget();
          }}
        />
      )}
      {showFilterMenu && filterIconBoundingRect && (
        <AutoCompleteSelectFilter
          potentialFilters={filtersWithOther}
          activeFilters={filters}
          placeholder='Search for theme'
          label='Filter responses by'
          disabled={false}
          position={filterIconBoundingRect}
          toggleFilter={(filter) => {
            setFilters((prev) => {
              if (prev.some((f) => areThemeIdsEqual(f, filter))) {
                return prev.filter((t) => !areThemeIdsEqual(t, filter));
              } else {
                return [...prev, filter];
              }
            });

            logger.info('Filter onSelectFilter: ', filter);
          }}
          onInputKeyDown={(inputValue) => {
            logger.info('Filter onInputKeyDown: ', inputValue);
          }}
          close={() => setShowFilterMenu(false)}
        />
      )}
      <LoadingModal hidden={!syncLoading} title='Syncing Tags...' />
      <ErrorModal
        show={!!syncError}
        onClose={resetLoadingAndError}
        title={'Tag All Error'}
        subTitle={syncError?.message || GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        hidden={!manualEditsLoading}
        title='Loading Manual Edits...'
      />
      {/* <ErrorModal
        show={!!manualEditsError}
        onClose={resetManualEditsError}
        title={'Fetching Manual Edits Error'}
        subTitle={manualEditsError?.message || GENERAL_ERROR_MESSAGE}
      /> */}
    </Container>
  );
};

export default TagResultsWithScrollToEnd;

const Container = styled(VerticalFlex)`
  border-radius: 16px;
  background: ${Colors.B10};
  padding: 16px 0;
  max-width: 669px;
`;

const TitleContainer = styled(HorizontalFlex)`
  gap: 10px;
`;

const Content = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 16px;
`;
const Buttons = styled(CenteredHorizontalFlex)`
  gap: 12px;
`;

const ContentsContainer = styled(VerticalFlex)`
  overflow-y: scroll;
  max-height: calc(100vh - 288px);
  padding: 6px 22px;
`;

const CommonButtonStlye = `
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

const StyledReloadIcon = styled(ReloadIcon)`
  ${CommonButtonStlye};
`;

const StyledFilterIcon = styled(FilterIcon)`
  ${CommonButtonStlye};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

import * as XLSX from 'xlsx';
import { Theme } from '../types';

export const downloadThemesFileUtil = (themes: Theme[], fileName: string) => {
  const partial = themes
    .map((item) => ({
      code_id: item.code_id,
      name: item.name,
      instructions: item.instructions,
    }))
    .sort((a, b) => (a.code_id || 0) - (b.code_id || 0));
  const worksheet = XLSX.utils.json_to_sheet(partial);

  // Create a new workbook and add the worksheet with a name
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Codebook');

  // Generate XLSX file and trigger a download
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CircledPlusIcon from './CircledPlusIcon';

const CircledPlusIconWithActive = styled(CircledPlusIcon)<{
  disabled?: boolean;
}>`
  &:hover {
    path {
      fill: ${Colors.P70};
    }
    rect {
      stroke: ${Colors.P40};
    }
  }

  &:active {
    path {
      fill: ${Colors.WHITE};
    }
    rect {
      fill: ${Colors.PRIMARY};
      stroke: transparent;
    }
  }
`;

export default CircledPlusIconWithActive;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PrimaryButton from '../components/buttons/PrimaryButton';
import PlusIcon from '../components/icons/PlusIcon';

import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import {
  createProjectApi,
  fetchProjectsApi,
  updateProjectApi,
} from '../api/pipelineApi';
import EmptyProjects from '../components/EmptyProjects';
import PageHeader from '../components/PageHeader';
import Separator from '../components/Seperator';
import StyledButtonWithActive from '../components/buttons/StyledButtonWithActive';
import Tooltip from '../components/buttons/Tooltip';
import CenteredHorizontalFlex from '../components/containers/CenteredHorizontalFlex';
import HorizontalFlex from '../components/containers/HorizontalFlex';
import PageContainer from '../components/containers/PageContainer';
import VerticalFlex from '../components/containers/VerticalFlex';
import InfoIcon from '../components/icons/InfoIcon';
import DownloadCodebookModal from '../components/modal/DownloadCodebookModal';
import ErrorModal from '../components/modal/ErrorModal';
import MessageModal from '../components/modal/MessageModal';
import H1 from '../components/newTextComponents/H1';
import P2 from '../components/newTextComponents/P2';
import ListProjectItem from '../components/project/ListProjectItem';
import ProjectNameInputModal from '../components/project/ProjectNameInputModal';
import LoadingModal from '../components/tagResults/LoadingModal';
import usePageVisibility from '../hooks/usePageVisibility';
import { useUser } from '../hooks/useUser';
import { useFormContext } from '../state/useFormContext';
import { Colors } from '../theme/Colors';
import { CreateProjectResponse, ProjectData } from '../types';
import {
  GENERAL_ERROR_MESSAGE,
  generalErrorMessages,
  unsupportedOldProjectStateErrorMessages,
} from '../utils/constants';
import logger from '../utils/logger';
import { imagesArray } from '../utils/utils';

interface ProjectsPageProps {}

const ProjectsPage: React.FC<PropsWithChildren<ProjectsPageProps>> = () => {
  const navigate = useNavigate();
  const user = useUser();
  const orgId = user?.organization_id;

  useEffect(() => {
    if (!user?.organization_id) {
      return;
    }

    const userId = user.id.toString();
    const email = user.email;
    const orgId = user.organization_id.toString();

    Sentry.setUser({ id: userId, email });
    Sentry.setTag('organization_id', orgId);

    Hotjar.identify(userId, {
      email: email,
      organizationId: orgId,
      environment: import.meta.env.VITE_ENVIRONMENT,
    });
  }, [user]);

  const queryClient = useQueryClient();
  const isTabVisible = usePageVisibility();

  const {
    mutate: createProjectMutate,
    isPending: createProjectIsLoading,
    error: createProjectError,
    reset: createProjectReset,
  } = useMutation<CreateProjectResponse, Error, string>({
    mutationFn: (projectName) => createProjectApi(orgId!, projectName),
    onSuccess: (data: { id: string }) => {
      navigate(`/projects/${data.id}/create-project`);
    },
  });

  const {
    data: getProjectsData,
    isPending: getProjectsIsLoading,
    error: getProjectsError,
    refetch: getProjectsRefetch,
  } = useQuery<any, Error>({
    queryKey: ['projects', orgId],
    queryFn: () => fetchProjectsApi(orgId!),
    enabled: !!orgId,
    retry: false,
    refetchOnWindowFocus: false,
    refetchInterval: isTabVisible ? 5000 : false,
  });

  const {
    mutate: updateProjectMutate,
    isPending: updateProjectIsLoading,
    error: updateProjectError,
    reset: updateProjectReset,
  } = useMutation<any, Error, any>({
    mutationFn: (updateData) =>
      updateProjectApi(updateData.projectId, updateData.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects', orgId] });
    },
  });

  const { setIsDemo, isDemo, setIncludeAggregateProjectIds } = useFormContext();

  const [tapCounter, setTapCounter] = useState(0);
  const [showIsDemoModal, setShowIsDemoModal] = useState(false);

  const [
    showDownloadCodebookModalWithProject,
    setShowDownloadCodebookModalWithProject,
  ] = useState<ProjectData | null>(null);

  const [includeAggregateProjectMap, setIncludeAggregateProjectMap] = useState<
    Record<number, boolean>
  >({});

  useEffect(() => {
    setIncludeAggregateProjectIds(
      Object.entries(includeAggregateProjectMap)
        .filter(([_, value]) => value)
        .map(([key]) => Number(key))
    );
  }, [includeAggregateProjectMap, setIncludeAggregateProjectIds]);

  const [showAggregate, setShowAggregate] = useState(false);
  useEffect(() => {
    if (orgId && [12, 2].includes(orgId)) {
      setShowAggregate(true);
    }
  }, [orgId]);
  const [newProjectName, setNewProjectName] = useState('');
  const [missingProjectNameError, setMissingProjectNameError] = useState('');
  const [showProjectNameModal, setShowProjectNameModal] = useState(false);
  const [oldProjectNotSupportedError, setOldProjectNotSupportedError] =
    useState<React.ReactNode | null>(null);
  const createProject = () => {
    if (!newProjectName || newProjectName.length < 2) {
      setMissingProjectNameError(
        'Please provide a name for your project to proceed'
      );
      return;
    }
    createProjectMutate(newProjectName);
  };

  const renameProject = (project: ProjectData, newName: string) => {
    if (!newName || newName.length < 2) {
      setMissingProjectNameError(
        'Please provide a name for your project to proceed'
      );
      return;
    }

    updateProjectMutate({
      projectId: project.id,
      data: { name: newName },
    });
  };

  const removeProject = (project: ProjectData) => {
    updateProjectMutate({
      projectId: project.id,
      data: { archived: true },
    });
  };

  const onEasterEggTap = () => {
    if (tapCounter > 6) {
      setTapCounter(0);
    } else if (tapCounter === 6) {
      setIsDemo((old) => !old);
      setShowIsDemoModal(true);
      setTapCounter(0);
    } else if (tapCounter === 0) {
      setTimeout(() => {
        if (setTapCounter) {
          setTapCounter(0);
        }
      }, 3000);
      setTapCounter(1);
    } else {
      setTapCounter((curr) => curr + 1);
    }
  };

  const clickProject = (project: ProjectData) => {
    logger.info(
      `clickProject: project.status: ${project.status} project.old_status: ${project.old_status}`
    );

    if (project.old_status) {
      switch (project.old_status) {
        case 'Processing':
          setOldProjectNotSupportedError(
            unsupportedOldProjectStateErrorMessages.PROCESSING
          );
          break;
        case 'Initial':
        case 'Failed':
          setOldProjectNotSupportedError(
            unsupportedOldProjectStateErrorMessages.JUST_STARTED_OR_FAILED
          );
          break;
        case 'Processed':
          navigate(
            `${project.id}/dashboard/${project.job_id}/${project.job_version_id}`
          );
          break;
        case 'Codebook':
          setShowDownloadCodebookModalWithProject(project);
          break;
        default:
          setOldProjectNotSupportedError(
            'Project status is missing, please contact admin'
          );
          break;
      }
    } else {
      switch (project.status) {
        case 'project_created':
        case 'file_uploaded':
          navigate(`${project.id}/create-project`);
          break;
        case 'data_columns_defined':
        case 'setup_completed':
          navigate(`${project.id}`);
          break;
        default:
          setOldProjectNotSupportedError(
            'Project status is missing, please contact admin'
          );
          break;
      }
    }
  };

  const isLoading = !orgId || getProjectsIsLoading;
  if (isLoading)
    return (
      <LoadingModal
        title='Fetching projects...'
        hidden={!createProjectIsLoading}
      />
    );
  if (getProjectsError)
    return (
      <ErrorModal
        show={!!getProjectsError}
        onClose={getProjectsRefetch}
        title='Network Issue Detected'
        subTitle={
          getProjectsError?.message || generalErrorMessages.GET_PROJECTS
        }
      />
    );

  if (!getProjectsData || getProjectsData.length === 0) {
    return (
      <Container>
        <PageHeader onClick={onEasterEggTap} />
        <EmptyProjects onCreateProject={() => setShowProjectNameModal(true)} />
        {showProjectNameModal ? (
          <ProjectNameInputModal
            show={showProjectNameModal}
            onClose={() => setShowProjectNameModal(false)}
            value={newProjectName}
            onChange={setNewProjectName}
            onCreateProject={createProject}
          />
        ) : null}
      </Container>
    );
  }

  return (
    <Container>
      <PageHeader />
      <PageContents>
        <HeaderContainer>
          <H1 onClick={onEasterEggTap}>Projects</H1>
          <HeaderButtonsContainer>
            {showAggregate ? (
              <PrimaryButton
                label='Show Trends Graph'
                onClick={() => navigate(`/projects/trends/${orgId}`)}
                withClearBackground
              />
            ) : null}
            <PrimaryButton
              label='New Project'
              onClick={() => setShowProjectNameModal(true)}
              icon={<PlusIcon />}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <List>
          <TableHeaderContainer>
            <ProjectNameHeader>Project Name</ProjectNameHeader>
            <QuestionsHeaderContainer>
              <QuestionsHeader>Questions</QuestionsHeader>
              <Tooltip toolTipText='Number of analyzed questions'>
                <InfoButtonWithActive icon={<InfoIcon />} onClick={() => {}} />
              </Tooltip>
            </QuestionsHeaderContainer>
            <CreatedHeader>Created</CreatedHeader>
          </TableHeaderContainer>
          <Separator />
          {getProjectsData.map((project: ProjectData) => (
            <div key={project.id}>
              <ListProjectItem
                project={{
                  ...project,
                  image: imagesArray[project.id % imagesArray.length],
                }}
                onClick={() => clickProject(project)}
                disabled={['Processing', 'Failed'].includes(
                  project.old_status ?? ''
                )}
                renameProject={(newName) => renameProject(project, newName)}
                removeProject={() => removeProject(project)}
                isDemo={isDemo}
                showAggregate={showAggregate}
                onSelectProjectAggregate={() => {
                  setIncludeAggregateProjectMap((old) => ({
                    ...old,
                    [project.id]: !old[project.id],
                  }));
                }}
                includeAggregateCheckbox={
                  includeAggregateProjectMap[project.id]
                }
              />
              <Separator />
            </div>
          ))}
        </List>
      </PageContents>
      {showProjectNameModal ? (
        <ProjectNameInputModal
          show={showProjectNameModal}
          onClose={() => setShowProjectNameModal(false)}
          value={newProjectName}
          onChange={setNewProjectName}
          onCreateProject={createProject}
        />
      ) : null}
      {showDownloadCodebookModalWithProject ? (
        <DownloadCodebookModal
          show={!!showDownloadCodebookModalWithProject}
          project={showDownloadCodebookModalWithProject}
          onClose={() => setShowDownloadCodebookModalWithProject(null)}
        />
      ) : null}
      <MessageModal
        show={showIsDemoModal}
        onClose={() => setShowIsDemoModal(false)}
        title='Demo mode 🥷'
        subTitle={`You are now ${!isDemo ? 'NOT ' : ''}in demo mode`}
      />

      <LoadingModal
        title='Creating your project...'
        hidden={!createProjectIsLoading}
      />
      <LoadingModal
        title='Updating project..'
        hidden={!updateProjectIsLoading}
      />
      <ErrorModal
        show={!!createProjectError}
        onClose={createProjectReset}
        title='Unexpected Error'
        subTitle={
          createProjectError?.message ?? generalErrorMessages.CREATE_PROJECT
        }
      />
      <ErrorModal
        show={!!oldProjectNotSupportedError}
        onClose={() => setOldProjectNotSupportedError(null)}
        title='New project structure'
        subTitle={oldProjectNotSupportedError}
      />
      <ErrorModal
        show={!!missingProjectNameError}
        onClose={() => setMissingProjectNameError('')}
        title='Project Name Required'
        subTitle={missingProjectNameError}
      />
      <ErrorModal
        show={!!updateProjectError}
        onClose={updateProjectReset}
        title='Failed to Update Project'
        subTitle={updateProjectError?.message ?? GENERAL_ERROR_MESSAGE}
      />
    </Container>
  );
};

export default ProjectsPage;

const Container = styled(PageContainer)`
  align-items: center;
`;

const PageContents = styled(VerticalFlex)`
  width: 1170px;
`;

const HeaderContainer = styled(CenteredHorizontalFlex)`
  justify-content: space-between;
  margin-bottom: 32px;
`;

const QuestionsHeaderContainer = styled(CenteredHorizontalFlex)`
  flex: 1;
`;

const TableHeaderContainer = styled(CenteredHorizontalFlex)`
  height: 56px;
  justify-content: space-between;
`;

const List = styled(VerticalFlex)``;

const Header = styled(P2)`
  font-weight: 600;
`;

const ProjectNameHeader = styled(Header)`
  flex: 2;
`;
const QuestionsHeader = styled(Header)`
  margin-right: 4px;
`;
const CreatedHeader = styled(Header)`
  flex: 1;
`;
const InfoButtonWithActive = styled(StyledButtonWithActive)`
  padding: 0;
  > svg rect {
    stroke: transparent;
  }
  &:hover > svg rect {
    stroke: ${Colors.B20};
  }
  &:active > svg path {
    stroke: ${Colors.P100};
  }
  &:active > svg rect {
    stroke: transparent;
  }
  &:active > svg {
    background-color: transparent;
  }
`;

const HeaderButtonsContainer = styled(HorizontalFlex)`
  gap: 12px;
`;

import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import ButtonWithIcon from '../buttons/ButtonWithIcon';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import XIcon from '../icons/XIcon';

import P3 from '../newTextComponents/P3';
interface TagProps {
  type: 'tag' | 'manualTag';
  text: string;
  onRemove?: () => void;
  isRTL?: boolean;
}

const Tag: React.FC<TagProps> = ({
  type = 'tag',
  text,
  onRemove,
  isRTL = false,
}) => {
  return (
    <Container>
      <ContentContainer
        $type={type}
        $reducedPadding={!!onRemove}
        $isRTL={isRTL}
      >
        <Text>{text}</Text>
        {onRemove && <CloseButton onClick={onRemove} icon={<XIcon />} />}
      </ContentContainer>
    </Container>
  );
};

export default Tag;

const Container = styled.div``;

const CloseButton = styled(ButtonWithIcon)`
  padding: 0;
`;

const ContentContainer = styled(CenteredHorizontalFlex)<{
  $type: 'tag' | 'manualTag' | 'pendingManualTag';
  $reducedPadding?: boolean;
  $isRTL?: boolean;
}>`
  padding: ${({ $reducedPadding, $isRTL }) =>
    $reducedPadding
      ? $isRTL
        ? '4px 16px 4px 12px'
        : '4px 12px 4px 16px'
      : '4px 16px'};
  background-color: ${({ $type }) =>
    $type === 'tag'
      ? Colors.P40
      : $type === 'manualTag'
      ? Colors.WHITE
      : Colors.B60};
  ${({ $type }) =>
    $type !== 'tag' &&
    `
    border: 1px solid ${Colors.P40};
  `}
  border-radius: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 6px;
`;

const Text = styled(P3)``;

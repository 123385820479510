import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../theme/Colors';
import CenteredHorizontalFlex from '../containers/CenteredHorizontalFlex';
import InfoIcon from '../icons/InfoIcon';
import H3 from '../newTextComponents/H3';
import P2 from '../newTextComponents/P2';

const CodebookFileSetupCard: React.FC = () => {
  return (
    <Container>
      <TopContainer>
        <StyledInfoIcon color={Colors.P100} />
        <TopHeader>How to Structure Your Codebook File:</TopHeader>
      </TopContainer>
      <List>
        <li>
          <P2>
            Upload an <strong>Excel</strong> or <strong>CSV</strong> file with
            exactly these columns: <strong>code_id, name, instructions</strong>
          </P2>
        </li>
        <li>
          <P2>
            The file should include only the column names above and values under
            each
          </P2>
        </li>
        <li>
          <P2>
            <strong>Code ID 99</strong> is reserved for the "Other" code and
            will be added automatically—no need to include it.
          </P2>
        </li>
      </List>
      <P2>You can download the example file to see the required format.</P2>
    </Container>
  );
};

export default CodebookFileSetupCard;

const Container = styled.div`
  padding: 4px;
  border-radius: 8px;
  max-width: 550px;
  width: 100%;
`;

const TopContainer = styled(CenteredHorizontalFlex)`
  gap: 12px;
`;

const List = styled.ul`
  padding-left: 20px;
  margin-bottom: 16px;
`;

const TopHeader = styled(H3)`
  color: ${Colors.P100};
`;

const StyledInfoIcon = styled(InfoIcon)`
  height: 20px;
  width: 20px;
  padding: 8px;
  background-color: ${Colors.P10};
  border-radius: 18px;
`;
